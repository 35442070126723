import React, { ReactElement, useEffect } from 'react';

import {
  FieldLabel,
  Heading,
  JourneyHeader,
  SupportiveText,
  Text,
} from '@constellation/core';
import { HeadingComponent } from 'components/summary/SummaryPage.styled';

import {
  ContentGroupDiv,
  GridContainer,
  GridItemContainer,
  Section,
} from './ErrorPage.styled';
import { ERROR } from '../../constants/errorConstants';
import { setAnalyticsPageEvent } from '../../utils/analytics-utilities';
import SurveyLink from 'components/summary/SurveyLink';

function ErrorPage(): ReactElement {
  useEffect(() => {
    setAnalyticsPageEvent(
      // tagging
      'inherit',
      'Error',
      ['Declined', `${ERROR.HEADING}`, ''],
      'Payments',
      '[TBC]',
    );
  }, []);

  return (
    <Section>
      <GridContainer>
        <GridItemContainer>
          <JourneyHeader>
            <Heading as="h3" size="s5" color="brand" data-testid="text" tabIndex={0}>
              {ERROR.HEADING}
            </Heading>
          </JourneyHeader>
        </GridItemContainer>
        <GridItemContainer>
          <ContentGroupDiv>
            <FieldLabel data-testid="label" tabIndex={0}>
              <HeadingComponent
                size="s3"
                marginBottom="none"
                data-testid="heading"
                color="brand"
                aria-level={3}
              >
                {ERROR.SUB_HEADING}
              </HeadingComponent>
            </FieldLabel>
            <SupportiveText tabIndex={0}>
              <Text size="s2">{ERROR.MESSAGE}</Text>
            </SupportiveText>
            <SurveyLink />
          </ContentGroupDiv>
        </GridItemContainer>
      </GridContainer>
    </Section>
  );
}

export default ErrorPage;
