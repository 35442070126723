import { Grid, Hr, Text, Link } from '@constellation/core';
import styled from 'styled-components';

export const FooterGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-weight: 400;
  padding: 0px 1.5rem;
  color: ${({ theme }) => theme.color_text_inverse_default_1};
`;

export const TextComponent = styled(Text)`
  margin: 0;
  text-align: left;
  padding-top: 24px;
  padding-botton: 24px;
`;

export const BackToTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

export const HR = styled(Hr)`
height: 2px;
align-self: stretch;
background: color_border_inverse_default_1;
color: #FFFFFF;
margin-bottom: 24px !important;
margin-top: 0px !important;
`

export const AnchorTag = styled(Link)`
text-decoration: none !important;
`