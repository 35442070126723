import React, { ReactElement } from 'react';

import { Footer } from '@constellation/core';

import { BackToTop, FooterGrid, HR, TextComponent, AnchorTag } from './FooterComponent.styled';
import dataQaIds from '../../examples/dataModel/dataQaIds';
import { getBrandFooter } from '../../utils/brand.util';
import { useAppLayoutContext } from '../../context/AppLayoutContext';
import { ChevronUp } from '@constellation/core/icons';
import useMobile from '../../utils/useIsMobileUtils';

export default function FooterComponent(): ReactElement {
  const { isTnCModalOpen } = useAppLayoutContext();
  const isMobile = useMobile();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <Footer marginTop="03" data-qa-id={dataQaIds.footer.container} aria-hidden={isTnCModalOpen}>
      <FooterGrid alignX="center">
        <TextComponent role='listitem' tabIndex={0}>
          {
            isMobile &&
            <BackToTop>
              <AnchorTag onClick={scrollToTop} icon={<ChevronUp trim />} iconPosition='right' >Back to top</AnchorTag>
              <HR />
            </BackToTop>
          }
          {getBrandFooter()}
        </TextComponent>
      </FooterGrid>
    </Footer>
  );
}
