import { Heading, JourneyHeader, Text } from '@constellation/core';
import { Negative, Positive, Warning } from '@constellation/core/icons';
import styled from 'styled-components';

export const Section = styled.section`
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const JourneyHeaderComponent = styled(JourneyHeader)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const HeadingComponent = styled(Heading)`
  padding-botton: 40px;
  gap: 10px;
  align-self: stretch;
`;

export const Subsummary = styled.div`
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const TickComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;
`;

export const Subheading = styled.div`
  padding-top: 12px;
  text-align: center;
`;

export const TextComponent = styled(Text)`
  margin: 0;
  text-align: center;
  padding-top: 12px;
`;

export const LogoComponent = styled(Positive)`
  width: 48px !important;
  height: 48px !important;
  flex-shrink: 0;
`;

export const LogoComponentDeclined = styled(Negative)`
  width: 48px !important;
  height: 48px !important;
  flex-shrink: 0;
`;

export const LogoComponentReferredHalifax = styled(Warning)`
  width: 48px !important;
  height: 48px !important;
  flex-shrink: 0;
`;

export const LogoComponentReferred = styled(Negative)`
  width: 48px !important;
  height: 48px !important;
  flex-shrink: 0;
`;

export const PaymentDetails = styled.div`
  display: flex;
  padding: 24px 0px;
  align-items: flex-start;
  align-self: stretch;

  @media only screen and {
    padding: 20px 0px;
  }
`;

export const PaymentSubDetails = styled.div`
  display: flex;
  width: 175px;
  flex-direction: column;
  align-items: flex-start;

  @media only screen and (max-width: 541px) {
    flex: 1 0 0;
  }
`;

export const KeyValue = styled.div`
  flex-basis: 100%;
  align-items: flex-start;
  display: flex;
`;

export const Span = styled.span`
  display: flex;
  height: 36px;
  padding-bottom: 4px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const HeadingName = styled(Text)`
  white-space: nowrap;
  align-self: stretch;
`;

export const Content = styled.div`
  display: flex;
  padding: 0px 24px 24px 0px;
  flex-direction: row;
  align-items: flex-start;
  align-self: stretch;

  @media only screen and (max-width: 541px) {
    flex-direction: column;
    gap: 4px;
  }
`;

export const HeadingValue = styled(Text)`
  white-space: nowrap;
  @media only screen and (max-width: 541px) {
    display: flex;
    align-items: flex-start;
    gap: 24px;
  }
`;

export const NotificationDiv = styled.div`
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
`;

export const SurveyLinkDiv = styled.div`
  width: 350px;
  height: 24px;
  margin-bottom: 24px;
`;
