import React, { ReactElement } from 'react'
import { SurveyLinkDiv } from './SummaryPage.styled'
import { Link, Text } from '@constellation/core'
import xlbgbrand from '../../utils/brand.util';
import { FIELD_NAMES, PAYMENT_OUTCOME } from '../../constants/pageConstants';
import { ChevronRight } from '@constellation/core/icons';

function SurveyLink(): ReactElement {
    const brand = xlbgbrand;
    const { lloydsLink, bosLink, halifaxLink } = PAYMENT_OUTCOME;
    const getBrandLink = (brand) => {
       return brand === 'lloyds' && lloydsLink || brand === 'halifax' && halifaxLink || brand === 'bos' && bosLink
    }

    return (
        <SurveyLinkDiv data-testid="links">
            <Link external href={getBrandLink(brand)} icon={<ChevronRight trim />} iconPosition='right'>
                <Text size='s2' weight='bold' color='inherit'>{FIELD_NAMES.surveyLinkText}</Text>
            </Link>
        </SurveyLinkDiv>
    )
}

export default SurveyLink