export const BRAND = {
  LLOYDS: 'lloyds',
  LLOYDS_ADDRESS:
    'Lloyds Bank plc. Registered Office: 25 Gresham Street, London EC2V 7HN. Registered in England and Wales no. 2065. Authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority under registration number 119278.',
  LLOYDS_TERMS_AND_CONDITONS_LINK:
    'https://www.lloydsbank.com/assets/media/pdfs/current-accounts/request-a-payment-payer-terms.pdf',
  LLOYDS_DATA_PRIVACY_POLICY:
    'https://www.lloydsbank.com/help-guidance/privacy/data-privacy-notice.html',
  BOS: 'bos', 
  BOS_ADDRESS:
    'Bank of Scotland plc. Registered Office: The Mound, Edinburgh EH1 1YZ. Registered in Scotland No. SC327000. Authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority under registration number 169628.',
  BOS_TERMS_AND_CONDITONS_LINK:
    'https://www.bankofscotland.co.uk/assets/pdf/bankaccounts/request-a-payment-payer-terms.pdf',
  BOS_DATA_PRIVACY_POLICY:
    'https://www.bankofscotland.co.uk/helpcentre/privacy/data-privacy-notice.html',
  HALIFAX: 'halifax', 
  HALIFAX_ADDRESS:
    'Halifax is a division of Bank of Scotland plc. Registered in Scotland No. SC327000.  Registered Office: The Mound, Edinburgh EH1 1YZ. Authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority under registration number 169628.',
  HALIFAX_TERMS_AND_CONDITONS_LINK:
    'https://www.halifax.co.uk/content/dam/halifax/pdf/bankaccounts/pdf/request-a-payment-payer-terms.pdf',
  HALIFAX_DATA_PRIVACY_POLICY:
    'https://www.halifax.co.uk/helpcentre/privacy/data-privacy-notice.html',
};
